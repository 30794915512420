import axios from "axios";
import { AudienceProvisionFetchFailedError, AudienceProvisionSaveFailedError, MadSDKAbortError, NotImplementedError } from "../../errors";
import { serviceOrgListItemToProvision, serviceProvisionToProvision, provisionToServiceProvision, ValidOrgTypesForProvisioning } from "../../models/audienceProvision";
import { parseNewNewYorkApiResponse } from "../../utils";
import { parseIdFilter } from "../handlers/filter";
import { Handler } from "../handlers";
/**
 * Class for handling audience provisioning CRUD operations.
 */
class AudienceProvisionsHandler extends Handler {
    constructor(sdk) {
        super(sdk, "audienceProvisions");
    }
    /**
     * Handles finding specific audienceProvision based on filters given. If no filters are
     * given, a list of all audience provisions is returned.
     *
     * @param filters filters to use within the find, currently only "id", "eq" is supported
     */
    async findItems(filters) {
        const idFilter = parseIdFilter(filters);
        const promise = idFilter.size === 1
            ? this.getOrgProvisionDetails(idFilter.values().next().value).then((provision) => [
                provision
            ])
            : this.getOrgProvisionList();
        return promise.catch((error) => {
            throw new AudienceProvisionFetchFailedError(error);
        });
    }
    /**
     * Saves audience provision data (packages/tiers) for an organization.
     *
     * @param data AudienceProvision to be updated.
     */
    async saveItem(data) {
        const url = `${this.sdk.urls.baseAPIUrl}/organization/${data.orgId}/audienceProvision`;
        const cancellationSource = axios.CancelToken.source();
        this.cache.clear();
        return new Promise((resolve, reject) => {
            axios
                .post(url, this.toServiceAudienceProvision(data), {
                headers: {
                    "Content-Type": "application/json"
                },
                cancelToken: cancellationSource.token
            })
                .then((res) => {
                try {
                    resolve(this.toAudienceProvision(res.data?.data));
                }
                catch (error) {
                    reject(new AudienceProvisionSaveFailedError(error));
                }
            })
                .catch((error) => {
                if (axios.isCancel(error)) {
                    reject(new MadSDKAbortError());
                }
                else {
                    reject(error);
                }
            });
        });
    }
    /**
     * NOT IMPLEMENTED - base class requires an implementation.
     */
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
    /**
     * Fetches audience data provisioning details for a particular organization.
     * @param id: an audienceProvision id.
     * @return: the audienceProvision with that id.
     */
    getOrgProvisionDetails(id) {
        const url = `${this.sdk.urls.baseAPIUrl}/organization/${id}/audienceProvision`;
        return this.cache.promise(id, async () => {
            try {
                const res = await axios.get(url, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const { data: rawAudienceProvision } = parseNewNewYorkApiResponse(res);
                return this.toAudienceProvision(rawAudienceProvision);
            }
            catch (error) {
                throw new AudienceProvisionFetchFailedError(error);
            }
        });
    }
    /**
     * Fetches a list of all orgs and necessary audience provision data.
     * @return: the list of all org audience provisions.
     */
    getOrgProvisionList() {
        const url = `${this.sdk.urls.baseAPIUrl}/organizations?orgTypes=${ValidOrgTypesForProvisioning.join("%2C")}`;
        return this.cache.promise(url, async () => {
            try {
                const res = await axios.get(url, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const { data: rawAudienceProvisions } = parseNewNewYorkApiResponse(res);
                return rawAudienceProvisions.map((serviceOrgListItem) => this.toAudienceProvisionFromOrgList(serviceOrgListItem));
            }
            catch (error) {
                throw new AudienceProvisionFetchFailedError(error);
            }
        });
    }
    /**
     * Extracts necessary data from an organization and transforms it into an AudienceProvision object.
     * @param serviceOrgListItem: an organization from the BE org list service.
     * @return: the transformed AudienceProvision formatted for FE components.
     */
    toAudienceProvisionFromOrgList(serviceOrgListItem) {
        return serviceOrgListItemToProvision(serviceOrgListItem);
    }
    /**
     * Converts a ServiceAudienceProvision (BE) -> AudienceProvision (FE).
     * @param serviceAudienceProvision: an audienceProvision from the BE service.
     * @return: the transformed audienceProvision formatted for FE components.
     */
    toAudienceProvision(serviceAudienceProvision) {
        return serviceProvisionToProvision(serviceAudienceProvision);
    }
    /**
     * Converts a AudienceProvision (FE) -> ServiceAudienceProvision (BE).
     * @param audienceProvision: an audienceProvision from a FE component.
     * @return: the transformed serviceAudienceProvision formatted for the BE service.
     */
    toServiceAudienceProvision(audienceProvision) {
        return provisionToServiceProvision(audienceProvision);
    }
}
export default AudienceProvisionsHandler;
