import React, { useMemo, useState } from "react";
import { FixedSizeList } from "react-window";
import { DropdownListProps } from "../types";
import DropdownItem from "../Item";

const DEFAULT_ROW_HEIGHT = 36;

interface VirtualizedListProps extends DropdownListProps {
  height?: string | number;
  itemSize?: number;
  width?: string | number;
}

export function VirtualizedList({
  height,
  itemSize,
  items,
  width,
  multiple,
  selected = [],
  hideSelected,
  fixed,
  max,
  cannotClear,
  lookupId,
  lookupLabel,
  lookupIcon,
  onListBlur,
  onItemFocus,
  onItemBlur,
  onSelectItem: incomingOnSelectItem
}: VirtualizedListProps) {
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();

  const selectedSet = useMemo(() => {
    return new Set(selected);
  }, [selected]);

  const getItemSize = () => itemSize || DEFAULT_ROW_HEIGHT;

  const visibleItems = useMemo(() => {
    if (!hideSelected) {
      return items;
    }
    const visibleItems = [];
    for (let i = 0; i < items.length; i++) {
      if (!selectedSet.has(String(lookupId(items[i])))) {
        visibleItems.push(items[i]);
      }
    }

    return visibleItems;
  }, [items, selected, hideSelected]);

  const setNextFocusableIndex = (
    itemIndex: number | undefined,
    direction: "prev" | "next"
  ) => {
    if (itemIndex === undefined) {
      return setFocusedIndex(itemIndex);
    }
    if (itemIndex < 0) {
      return onListBlur?.("prev");
    }
    if (itemIndex >= visibleItems.length) {
      return onListBlur?.("next");
    }

    const id = visibleItems[itemIndex] && lookupId(visibleItems[itemIndex]);
    if (id && !fixed?.has(id)) {
      return setFocusedIndex(itemIndex);
    }

    const getNextFocusableIndex = (
      itemIndex: number,
      direction: "prev" | "next"
    ) => {
      const directionValue = direction === "prev" ? -1 : 1;
      let nextFocusableIndex = itemIndex + directionValue;
      for (
        let i = itemIndex + directionValue;
        i >= 0 && i < visibleItems.length;
        i += directionValue
      ) {
        const id = items[i] && lookupId(items[i]);
        if (!id || fixed?.has(id)) {
          nextFocusableIndex += directionValue;
          continue;
        }
        break;
      }

      if (nextFocusableIndex >= visibleItems.length) {
        nextFocusableIndex = Infinity;
      }
      return nextFocusableIndex;
    };

    const nextFocusableIndex = getNextFocusableIndex(itemIndex, direction);

    setFocusedIndex(nextFocusableIndex);

    if (nextFocusableIndex < 0 || nextFocusableIndex === Infinity) {
      onListBlur?.(direction);
    }
  };

  const onSelectItem = (id: string, itemIndex: number) => {
    incomingOnSelectItem(id);
    if (selected.includes(id)) {
      return setNextFocusableIndex(itemIndex - 1, "prev");
    }
    if (visibleItems.length === 1) {
      return onListBlur?.("next");
    }
    setNextFocusableIndex(itemIndex, "next");
  };

  const onItemKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    itemIndex: number
  ) => {
    switch (e.key) {
      case "Tab": {
        e.preventDefault();
        if (e.shiftKey) {
          setNextFocusableIndex(itemIndex - 1, "prev");
          break;
        }
        setNextFocusableIndex(itemIndex + 1, "next");
        break;
      }
      case "ArrowUp": {
        setNextFocusableIndex(itemIndex - 1, "prev");
        break;
      }
      case "ArrowDown": {
        setNextFocusableIndex(itemIndex + 1, "next");
        break;
      }
      case "Delete":
      case "Backspace": {
        const id = items[itemIndex] && lookupId(items[itemIndex]);
        if (id && !fixed?.has(id) && !cannotClear && selected.includes(id)) {
          onSelectItem(id, itemIndex);
        }
        break;
      }
      default:
        break;
    }
  };

  const ListItem = ({
    index,
    style
  }: {
    index: number;
    style: React.CSSProperties;
  }) => {
    const id = !!visibleItems[index] && lookupId(visibleItems[index]);
    if (!id) {
      return null;
    }

    const clearable = multiple ? !fixed?.has(id) : !cannotClear;
    const isSelected = selectedSet.has(id);

    return (
      <div style={style}>
        <DropdownItem
          item={items[index]}
          isSelected={isSelected}
          focus={focusedIndex === index}
          onSelectItem={id => onSelectItem(id, index)}
          key={id}
          onKeyDown={e => onItemKeyDown(e, index)}
          lookupId={lookupId}
          lookupLabel={lookupLabel}
          lookupIcon={lookupIcon}
          onFocus={e => onItemFocus?.(e, visibleItems[index], index)}
          onBlur={e => {
            onItemBlur?.(e, visibleItems[index], index);
          }}
          disabled={!!max && selected?.length >= max}
          clearable={clearable}
        />
      </div>
    );
  };

  return (
    <FixedSizeList
      height={height || "100%"}
      itemCount={items.length}
      itemSize={getItemSize()}
      width={width || "100%"}
    >
      {ListItem}
    </FixedSizeList>
  );
}
