import { RemoteConfigProperties } from "@madhive/mad-sdk";
import { ff } from "lib/utils/featureFlags";

/**
 * Can be used within TS files to determine if a client has pace to budget enabled.
 * Note that is just a temporary solution, until CAMP-75 and CAMP-390 are completed.
 * After which this all instances of this function can be replaced with:
 *   await ff(RemoteConfigProperties.PACE_TO_BUDGET)
 *
 * @returns A promise that resolves to either true or false.
 */
export const isPaceToBudgetEnabled = async () => {
  const {
    LEGACY_LINE_ITEM,
    LEGACY_PACE_TO_BUDGET,
    OMS_CLIENTS,
    PACE_TO_BUDGET_WITH_GOAL_INTERVAL
  } = RemoteConfigProperties;

  const isLegacy = await ff(LEGACY_LINE_ITEM);

  return isLegacy
    ? !(await ff(LEGACY_PACE_TO_BUDGET)) ||
        ff(PACE_TO_BUDGET_WITH_GOAL_INTERVAL)
    : !(await ff(OMS_CLIENTS));
};
