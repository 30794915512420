import {
  useRef,
  useImperativeHandle,
  useState,
  useEffect,
  useCallback
} from "react";
import { css } from "@emotion/react";
import { Spinner, SpinnerSize } from "madhive/components";
import { DoNotCare } from "frontier/lib/kit/types";
import Button from "../Button";
import * as Icons from "../Icons";
import Input from "../Input";
import { DropdownListSetFocusRef, DropdownProps } from "./types";
import { isPropsMultiSelect, isPropsSingleSelect } from "../Select/types";
import { styles } from "./styles";
import DropdownList from "./List";
import useDropdown from "./hooks/useDropdown";
import { useSizingProp } from "../../hooks/props/useSizingProp";
import Flyout from "../Flyout";
import { useSelectedIds } from "../../hooks";
import { VirtualizedList } from "./VirtualizedList";

const Dropdown = <
  Selected extends string[] | string | undefined,
  Item extends Record<string, DoNotCare> | string,
  Section extends Record<string, DoNotCare> | string =
    | Record<string, DoNotCare>
    | string
>({
  cannotClear = false,
  pinned,
  displayCap,
  error,
  items,
  itemsMap,
  sections: incomingSections,
  max,
  selected: incomingSelected,
  fixed: incomingFixed,
  height: incomingHeight = "auto",
  minWidth: incomingMinWidth = "240px",
  maxWidth: incomingMaxWidth = "none",
  width: incomingWidth = "auto",
  fill,
  searchable = false,
  search,
  children: target,
  flyoutProps = {},
  setFocusRef,
  lookupId,
  lookupLabel,
  lookupSection,
  lookupSectionId,
  lookupSectionTitle,
  lookupIcon,
  onSelect,
  onItemBlur,
  onItemFocus,
  onDropdownBlur,
  isStyled = true,
  hideSelected = false,
  isVirtualized = false
}: DropdownProps<Selected, Item, Section>) => {
  const isMulti = Array.isArray(incomingSelected);

  const { selectedIds, fixed } = useSelectedIds(
    incomingSelected,
    incomingFixed
  );
  const dropdownRef = useRef<HTMLDivElement>(null);

  const width = useSizingProp(fill ? "100%" : incomingWidth, "auto");
  const height = useSizingProp(incomingHeight, "auto");
  const minWidth = useSizingProp(incomingMinWidth, "auto");
  const maxWidth = useSizingProp(incomingMaxWidth, "none");

  let pinnedFocusRef: DropdownListSetFocusRef | null = null;
  const listFocusRefs: Record<number, DropdownListSetFocusRef | null> = {};

  const handleOnSelect = (selected: string[]) => {
    const selectArgs = {
      selected: incomingSelected,
      onSelect
    };

    if (isPropsMultiSelect(selectArgs)) {
      selectArgs.onSelect(selected);
    } else if (isPropsSingleSelect(selectArgs)) {
      selectArgs.onSelect(selected[0]);
    }
  };

  const {
    cappedItems,
    selectedItems,
    selectedCount,
    clearable,
    query,
    isLoading,
    sections,
    setQuery,
    clearSelected,
    onSelectItem
  } = useDropdown<Item>({
    cannotClear,
    displayCap,
    items,
    itemsMap,
    selected: selectedIds,
    fixed,
    searchable,
    search,
    isMulti,
    sections: incomingSections,
    hideSelected: hideSelected || pinned,
    onSelect: handleOnSelect,
    lookupId,
    lookupLabel,
    lookupSection,
    lookupSectionId,
    lookupSectionTitle
  });

  const handleSetFocusedItem = useCallback(
    (index: number | undefined) => {
      if (typeof index !== "number") {
        for (const listFocusRef of Object.values(listFocusRefs)) {
          listFocusRef?.(undefined);
        }
        pinnedFocusRef?.(undefined);
        return;
      }

      if (pinned && Array.isArray(selectedIds) && index < selectedIds.length) {
        pinnedFocusRef?.(index);
      } else if (index === selectedIds.length) {
        pinnedFocusRef?.(undefined);
        listFocusRefs[0]?.(0);
      } else {
        const nextIndex = index;
        let indexRange = 0;
        const nextSectionIndex = sections.reduce<null | number>(
          (acc, section, sectionIndex) => {
            if (acc !== null) {
              return acc;
            }
            indexRange += section.visibleCount;
            if (indexRange > nextIndex) {
              return sectionIndex;
            }
            return acc;
          },
          null
        );
        const listFocusRef = listFocusRefs[nextSectionIndex || 0];
        const offset =
          sections
            .slice(0, nextSectionIndex || 0)
            .reduce((acc, section) => acc + section.visibleCount, 0) || 0;
        listFocusRef?.(nextIndex - offset);
      }
    },
    [pinnedFocusRef, listFocusRefs, selectedIds, sections]
  );

  const focusSearchInput = () => {
    dropdownRef.current
      ?.querySelector<HTMLInputElement>(".dropdown-search-input input")
      ?.focus();
  };

  useImperativeHandle(setFocusRef, () => ({
    search: focusSearchInput,
    itemIndex: handleSetFocusedItem
  }));

  const [isOpen, setIsOpen] = useState(!!flyoutProps?.isOpen);

  useEffect(() => setIsOpen(!!flyoutProps?.isOpen), [flyoutProps?.isOpen]);

  const handleOnClose = () => {
    setIsOpen(false);
    setQuery("");
    flyoutProps?.onClose?.();
  };

  const handleOnOpen = () => {
    setTimeout(() => {
      if (searchable) {
        return search?.focusOnMount !== false && focusSearchInput();
      }
      handleSetFocusedItem?.(0);
    });
  };

  const handleSearchOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case "Enter": {
        if (!e.currentTarget.value) {
          return;
        }
        let firstSelectable: string | undefined;
        for (let i = 0; i < cappedItems.length; i++) {
          const id = lookupId(cappedItems[i]);
          if (id && !selectedIds.includes(id)) {
            firstSelectable = id;
            break;
          }
        }
        firstSelectable && onSelectItem(firstSelectable);
        break;
      }
      case "Tab": {
        e.preventDefault();
        if (e.shiftKey) {
          setIsOpen(false);
          onDropdownBlur?.("prev");
          break;
        }
        handleSetFocusedItem?.(0);
        break;
      }
      case "ArrowDown": {
        handleSetFocusedItem?.(0);
        break;
      }
      case "ArrowUp": {
        setIsOpen(false);
        onDropdownBlur?.("prev");
        break;
      }
      default:
        break;
    }
    search?.onKeyDown?.(e);
  };

  const focusSearchOrPrevNode = () => {
    if (searchable) {
      if (dropdownRef.current) {
        focusSearchInput();
      } else {
        onDropdownBlur?.("prev");
      }
    } else {
      onDropdownBlur?.("prev");
    }
  };

  const handleOnListBlur = (
    direction: "prev" | "next",
    isPinnedList = false,
    sectionIndex = 0
  ) => {
    switch (direction) {
      case "prev": {
        if (isPinnedList) {
          focusSearchOrPrevNode();
        } else if (sectionIndex > 0) {
          const itemCount = sections
            .slice(0, sectionIndex)
            .reduce((acc, section) => acc + section.visibleCount, 0);
          handleSetFocusedItem?.(itemCount - 1);
        } else if (!pinned || selectedCount === (fixed?.size || 0)) {
          focusSearchOrPrevNode();
        } else {
          handleSetFocusedItem?.(selectedCount - 1);
        }
        break;
      }
      case "next": {
        if (isPinnedList) {
          handleSetFocusedItem?.(selectedCount);
        } else if (sectionIndex < sections.length - 1) {
          const itemCount = sections
            .slice(0, sectionIndex + 1)
            .reduce((acc, section) => acc + section.visibleCount, 0);

          handleSetFocusedItem?.(itemCount + 1);
        } else {
          setIsOpen(false);
          onDropdownBlur?.("next");
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <Flyout
      position="bottom"
      offset="0, 4px"
      fill={fill}
      {...flyoutProps}
      autoFocus={flyoutProps?.autoFocus === true}
      enforceFocus={flyoutProps?.enforceFocus === true}
      disabled={!target}
      target={target || <span />}
      isOpen={isOpen}
      onOpen={handleOnOpen}
      onClose={handleOnClose}
      boundary="scrollParent"
    >
      <div
        className="kit-Dropdown"
        ref={dropdownRef}
        role="menu"
        css={[
          isStyled && styles.base,
          fill && styles.fill,
          css`
            min-width: ${minWidth};
            max-width: ${maxWidth};
            width: ${width};
            padding-top: ${!searchable ? "var(--spacing-4)" : "0"};
          `
        ]}
      >
        {searchable && (
          <span className="dropdown-search-input">
            <Input
              value={query}
              onChange={setQuery}
              onClick={e => e.stopPropagation()}
              onKeyDown={handleSearchOnKeyDown}
              prefix="Search"
              type="text"
              fill
              placeholder={search?.placeholder || "Search..."}
            />
          </span>
        )}
        {isMulti && selectedCount > 0 && (
          <div css={[styles.selectedCount]}>
            <span>{`${selectedCount} selected`}</span>
            {clearable && (
              <Button variant="ghost" onClick={clearSelected}>
                Clear
              </Button>
            )}
          </div>
        )}
        {isVirtualized && (
          <VirtualizedList
            width="auto"
            height={300}
            itemSize={36}
            multiple
            setFocusRef={ref => (pinnedFocusRef = ref)}
            items={cappedItems}
            selected={selectedIds}
            fixed={fixed}
            onItemFocus={onItemFocus}
            onItemBlur={onItemBlur}
            onListBlur={direction => handleOnListBlur(direction, true)}
            lookupId={lookupId}
            lookupLabel={lookupLabel}
            lookupIcon={lookupIcon}
            onSelectItem={onSelectItem}
          />
        )}
        {!isVirtualized && (
          <div
            className="dropdown-scroll-area"
            role="listbox"
            css={[
              styles.scrollArea,
              css`
                max-height: ${height};
              `
            ]}
          >
            {isMulti && selectedCount > 0 && pinned && (
              <div css={[styles.pinnedItems]}>
                <DropdownList
                  multiple
                  setFocusRef={ref => (pinnedFocusRef = ref)}
                  items={selectedItems}
                  selected={selectedIds}
                  fixed={fixed}
                  onItemFocus={onItemFocus}
                  onItemBlur={onItemBlur}
                  onListBlur={direction => handleOnListBlur(direction, true)}
                  lookupId={lookupId}
                  lookupLabel={lookupLabel}
                  lookupIcon={lookupIcon}
                  onSelectItem={onSelectItem}
                />
              </div>
            )}
            {error && (
              <div
                css={[styles.infoSection, styles.infoSectionError]}
                data-testid="dropdown-info-error"
              >
                <Icons.Display size="small">
                  <Icons.Circled.X />
                </Icons.Display>
                <span>Error loading results. </span>
                {search?.onRetry && (
                  <Button onClick={search?.onRetry} variant="ghost">
                    Retry
                  </Button>
                )}
              </div>
            )}
            {!error && isLoading && (
              <div css={styles.infoSection}>
                <Spinner size={SpinnerSize.SMALL} />
                <span>Loading results</span>
              </div>
            )}
            {!error && !isLoading && cappedItems.length === 0 && (
              <div
                css={styles.infoSection}
                data-testid="dropdown-info-no-results"
              >
                <Icons.Display size="small">
                  <Icons.SearchEmpty />
                </Icons.Display>
                <span>No results found</span>
              </div>
            )}
            {!error &&
              !isLoading &&
              cappedItems.length > 0 &&
              sections.map((section, i) => (
                <DropdownList
                  key={section.title}
                  setFocusRef={ref => (listFocusRefs[i] = ref)}
                  bordered={
                    (isMulti &&
                      (pinned || !!incomingSections?.length) &&
                      selectedCount > 0) ||
                    i > 0
                  }
                  multiple={isMulti}
                  items={section.items}
                  max={max}
                  fixed={fixed}
                  selected={section.selected}
                  onItemFocus={onItemFocus}
                  onItemBlur={onItemBlur}
                  onListBlur={direction => {
                    if (
                      (i === 0 && direction === "prev") ||
                      (i === sections.length - 1 && direction === "next")
                    ) {
                      return handleOnListBlur(direction, false, i);
                    }
                    const nextSectionIndex =
                      direction === "next" ? i + 1 : i - 1;
                    const itemIndex =
                      direction === "next"
                        ? 0
                        : sections[nextSectionIndex].visibleCount - 1;
                    listFocusRefs[nextSectionIndex]?.(itemIndex);
                  }}
                  hideSelected={hideSelected || (isMulti && pinned)}
                  cannotClear={cannotClear}
                  title={section.title}
                  lookupId={lookupId}
                  lookupLabel={lookupLabel}
                  lookupIcon={lookupIcon}
                  onSelectItem={onSelectItem}
                />
              ))}
          </div>
        )}
      </div>
    </Flyout>
  );
};

export default Dropdown;
export * from "./types";
