import { useMemo } from "react";

/**
 * This hook keeps track of an array items as Map<itemId, item>.
 * It is intended to be used inside components that utilize lookupId to match get the id of an item in an array of items.
 * - Improves performance by making lookups less expensive than looping through an array and calling lookupId on each item.
 * - In a component that is used inside another component that also uses this hook,
 *     - the inner component can pass in a map of items to the overrideMap — forgoing the need to loop through items to build it's own map
 *     - or render it's itemsMap if it is not being used as inner component.
 * @param items array of items to set in map
 * @param lookupId method for looking up item's id
 */
export const useItemsMap = <Item = unknown>(
  /**
   * array of items to set in map or override map
   * @type Item[]: array of items to set in map
   * @type Map<string, Item>: map of items to override map
   */
  items: Item[] | Map<string, Item>,
  /** method for looking up item's id */
  lookupId: (item: Item | undefined) => string | undefined
): Map<string, Item> => {
  return useMemo(() => {
    if (items instanceof Map) {
      return items;
    }
    const newMap = new Map<string, Item>();
    if (!items?.length) {
      return newMap;
    }
    /** setting the incoming items in map */
    for (let i = 0; i < items.length; i++) {
      const id = lookupId(items[i]);
      if (id) {
        newMap.set(id, items[i]);
      }
    }
    return newMap;
  }, [items, lookupId]);
};
